import React from "react";
import Footer from "../components/layout/footer/Footer"
import ButtonGroup from "../components/ui/atom/button/button-group/ButtonGroup"
import { LogoTeaser } from "../components/ui/atom/logo/Logo"

import * as Styles from "./survey.module.scss";
import Button from "../components/ui/atom/button/Button";
import {Helmet} from "react-helmet";


const Survey = () =>
    <>
        <Helmet>
            <title>Karmalokal · Die Spendenplattform für Geld-, Sach- und Zeitspenden</title>
        </Helmet>

        <section className={ Styles.section }>
            <LogoTeaser logoClassName={ Styles.logo }/>

            <div className={ Styles.description }>
                <svg viewBox='0 0 308 108' preserveAspectRatio='none'>
                    <rect x="4" y="4" width="300" height="100" rx="8" vectorEffect='non-scaling-stroke'/>
                </svg>

                <h4>
                    Umfrage
                </h4>

                <p>
                    Im Rahmen meiner Bachelorarbeit (FH Potsdam, Design) konzipiere ich eine Plattform, die Spenden zwischen Vereinen und Unternehmen vermittelt. Mit Ihren Angaben helfen Sie mir besser auf Ihre Bedürfnisse und Probleme eingehen zu&nbsp;können.
                </p>

                <p>
                    <em>
                        Herzlichst <br/>
                        Joseph Ribbe
                    </em>
                </p>

                <ButtonGroup position={ "centered" }>
                    <Button
                        renderAs={ "a" }
                        href={ "https://docs.google.com/forms/d/e/1FAIpQLSdy9GAr52ldP4UH08TWqmZOyh-ZZPWBkUnqueYtoDAyflLQFA/viewform" }
                        target={ "_blank" }>
                        Umfrage für Vereine
                    </Button>

                    <Button
                        renderAs={ "a" }
                        href={ "https://docs.google.com/forms/d/e/1FAIpQLScGlVSJpZ8Rt5Ujyu5VbWfuqThmNo8kFoXEmjzNppX52Yu82A/viewform" }
                        target={ "_blank" }
                    >
                        Umfrage für Unternehmen
                    </Button>
                </ButtonGroup>
            </div>
        </section>

        <Footer/>
    </>;

export default Survey;
